import Drawer from '@mui/material/Drawer';
import { withTheme } from '@mui/styles';
import styled from 'styled-components';

const SearchDrawerStyle = withTheme(styled(Drawer)`
  width: 100%;
  height: 100%;
  background-color: white;
  .MuiDrawer-paper {
    height: 100%;
  }
  .header {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 64px;
    padding: 8px 16px;
  }
  .close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.04);
  }
  .icon-size-18 {
    font-size: 18px;
  }

  ${(props: any) => props.theme.breakpoints.down('md')} {
    .header {
      padding: 8px 0.5rem;
    }
  }
`);

export default SearchDrawerStyle;
