import { useEffect, useRef, useState } from 'react';

import Button from '@mui/material/Button';
import { useTranslation } from 'next-i18next';

import SearchBar from '../searchBar';

import SearchDrawerStyle from './style';

import { useWindowSize } from 'src/helpers/use';

type SearchDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

const SearchDrawer = ({ isOpen, onClose }: SearchDrawerProps) => {
  const { width = 0 } = useWindowSize();
  const { t } = useTranslation('common');

  useEffect(() => {
    onClose();
  }, [width]);

  return (
    <SearchDrawerStyle
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <div className="header">
        <SearchBar closeDrawer={() => onClose()} />
        <Button sx={{ color: 'primary.main' }} variant="text" onClick={onClose}>
          {t('cancel')}
        </Button>
      </div>
    </SearchDrawerStyle>
  );
};

export default SearchDrawer;
